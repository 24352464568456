<template>
  <!-- https://burning-heart.atlassian.net/browse/FND-2927 -->
  <TTView v-if="false">
    <VRow>
      <VCol>
        <span class="text-h6">Штатные должности</span>
      </VCol>
    </VRow>
    <VRow v-if="staffPositionList.length===0">
      <VCol>
        <span class="text-body-2 grey--text">Нет штатных должностей в команде</span>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VBtn
          v-if="!staffPositionsEditable"
          color="primary"
          :disabled="loading"
          @click="createStaffPositionDialog=true"
        >
          Создать штатную должность
        </VBtn>
        <VBtn
          color="primary"
          text
          :class="{'ml-3' : !staffPositionsEditable}"
          :disabled="loading"
          @click="staffPositionsEditable=!staffPositionsEditable"
        >
          <span v-if="!staffPositionsEditable">Настроить связи</span>
          <span v-else>Отмена</span>
        </VBtn>
      </vcol>
    </VRow>
    <VRow>
      <VCol
        cols="8"
      >
        <TreeList
          v-if="staffPositionList.length"
          class="mt-6"
          :is-edit="staffPositionsEditable"
          :items="staffPositionTree"
        />
      </VCol>
      <VCol
        v-if="staffPositionsEditable && staffPositionSelected.length"
        cols="4"
      >
        <VCard
          class="mt-6"
        >
          <VCardTitle>
            Выбранные штатные должности
          </VCardTitle>
          <VCardText class="d-flex pb-0 flex-column">
            <VChip
              v-for="sp in staffPositionSelected"
              :key="sp.id"
              close
              class="staff-position-chip mb-3"
              @click:close="sp.isSelected=false"
            >
              {{ sp.name }}
            </VChip>
          </VCardText>
          <VCardText>
            <VBtn
              small
              color="primary"
              @click="createStaffPositionLinkDialog=true"
            >
              Назначить руководящую должность
            </VBtn>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
    <VDialog
      v-model="createStaffPositionDialog"
      width="576"
    >
      <StaffPositionForm
        :is-show="createStaffPositionDialog"
        :team="team"
        @submit="handleCreateSP"
        @cancel="handlerCancelSPForm"
      />
    </VDialog>
    <VDialog
      v-model="createStaffPositionLinkDialog"
      width="528"
    >
      <StaffPositionLinkForm
        :staff-positions-list="staffPositionList"
        @cancel="createStaffPositionLinkDialog=false"
        @submit="createStaffPositionLink"
      />
    </VDialog>
  </TTView>
</template>

<script>
import StaffPositionForm from '../../../components/v2/StaffPositionForm.vue';
import StaffPositionLinkForm from '../../../components/v2/StaffPositionLinkForm.vue';
import TreeList from '../../../components/v2/ui/TreeList.vue';

export default {
  name: 'StaffPositionList',
  components: { StaffPositionForm, StaffPositionLinkForm, TreeList },
  props: {
    teamId: {
      type: String,
      required: true,
    },
    companyId: { type: String, required: true },
  },
  data() {
    return {
      team: null,
      loading: false,
      createStaffPositionDialog: false,
      createStaffPositionLinkDialog: false,
      staffPositionsEditable: false,
      staffPositionList: [],
    };
  },
  computed: {
    staffPositionTree() {
      const map = {};
      const list = [...this.staffPositionList];
      const childrenItemsId = [];

      for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        list[i].children = [];
        if (!list[i].name) list[i].name = 'Отсутствует имя';
      }

      for (let i = 0; i < list.length; i += 1) {
        if (list[i].staffPositionToStaffPositions.length) {
          list[i].staffPositionToStaffPositions.forEach((el) => {
            const childrenItem = list[map[el.toStaffPositionId]];
            list[i].children.push(childrenItem);
            childrenItemsId.push(childrenItem.id);
          });
        }
      }

      return list.filter((el) => !childrenItemsId.includes(el.id));
    },
    staffPositionSelected() {
      return this.staffPositionList.filter((e) => e.isSelected);
    },
  },
  watch: {
    staffPositionsEditable(v) {
      if (!v) {
        this.clearStaffPositionSelected();
      }
    },
    teamId: {
      handler: 'fetch', immediate: true,
    },
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;
        await this.getTeam();
        await this.getStaffPositions();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async getTeam() {
      const app = new this.$di.ddd.Orgstructure();
      const [team] = await app.services.team.getTeam({ id: this.teamId, companyId: this.companyId });
      this.team = { ...team };
    },
    async getStaffPositions() {
      const app = new this.$di.ddd.Orgstructure();
      const [staffPositions] = await app.services.team.getTeamStaffPositions({ teamId: this.teamId });
      this.staffPositionList = staffPositions;
    },
    async handleCreateSP(sp) {
      try {
        const app = new this.$di.ddd.Orgstructure();
        await app.services.team.createStaffPosition(sp);
        this.$di.notify.snackSuccess('Штатная должность успешно создана');
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.handlerCancelSPForm();
      }
    },
    handlerCancelSPForm() {
      this.createStaffPositionDialog = false;
    },
    clearStaffPositionSelected() {
      this.staffPositionSelected.forEach((e) => {
        e.isSelected = false;
      });
    },
    async createStaffPositionLink({ parentId }) {
      const app = new this.$di.ddd.Orgstructure();
      try {
        await Promise.all(this.staffPositionSelected.map(async (item) => {
          await app.services.team.linkStaffPositions({
            toStaffPositionId: item.id,
            fromStaffPositionId: parentId,
            role: 'chief',
          });
        }));
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.staffPositionsEditable = false;
        this.createStaffPositionLinkDialog = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-position-chip{
  width: fit-content;
}
</style>
