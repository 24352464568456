<template>
  <div>
    <VCol
      cols="6"
      :offset="offset"
      class="staff-position-card tt-text-body-1"
    >
      {{ item.name }} <VSpacer />
      <!-- FIXME: пока грязное решение -->
      <!-- eslint-disable vue/no-mutating-props -->
      <VCheckbox
        v-if="isEdit"
        v-model="item.isSelected"
        class="mt-0 ml-3 pt-0"
        hide-details
        dense
      />
      <!-- eslint-enable vue/no-mutating-props -->
    </VCol>
    <TreeItem
      v-for="i in item.children"
      :key="i.id"
      :item="i"
      :offset="offset+1"
      :is-edit="isEdit"
    />
  </div>
</template>

<script>
export default {
  name: 'TreeItem',
  props: {
    item: { type: Object, required: true },
    isEdit: { type: Boolean, default: false },
    offset: { type: Number, default: 0 },
  },
  data: () => ({
  }),
};
</script>

<style lang="scss" scoped>
  .staff-position-card{
    background: #F5F6F6;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
    min-height: 60px;
    display: flex;
    align-items: center;
  }
</style>
