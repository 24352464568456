<template>
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VForm class="pa-6">
      <VRow justify="space-between">
        <VCol>
          <span class="tt-text-title-2">Создание штатной должности</span>
        </VCol>
        <VCol
          cols="auto"
          class="mr-auto"
        >
          <TTBtn
            color="tt-light-mono-100"
            icon
            @click="handleCancel"
          >
            <VIcon>fal fa-times</VIcon>
          </TTBtn>
        </VCol>
      </VRow>

      <VRow>
        <VCol>
          <!-- FIXME: пока грязное решение -->
          <!-- eslint-disable vue/no-mutating-props -->
          <TTTextField
            v-model="team.name"
            label="Название команды"
            large
            disabled
            hide-details="auto"
          />
          <!-- eslint-enable vue/no-mutating-props -->
        </VCol>
      </VRow>

      <VRow>
        <VCol>
          <TTTextField
            v-model="entity.name"
            label="Название штатной должности"
            large
            hide-details="auto"
          />
        </VCol>
      </VRow>

      <VRow
        class="mt-5"
        justify="end"
      >
        <VCol cols="auto">
          <TTBtn
            class="mr-5"
            color="tt-secondary"
            large
            @click="handleCancel"
          >
            Отменить
          </TTBtn>
          <TTBtn
            color="tt-primary"
            large
            :disabled="!entity.name"
            @click="handleSubmit"
          >
            Создать штатную должность
          </TTBtn>
        </VCol>
      </VRow>
    </VForm>
  </VCard>
</template>

<script>

export default {
  name: 'StaffPositionForm',
  props: {
    team: { type: Object, required: true },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entity: { name: null, teamId: this.team.id },
      loading: false,
    };
  },
  watch: {
    isShow(val) {
      if (!val) {
        this.resetState();
      }
    },
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleSubmit() {
      this.loading = true;
      this.$emit('submit', this.entity);
    },
    resetState() {
      this.entity = { name: null, teamId: this.team.id };
      this.loading = false;
    },
  },
};

</script>
