<template>
  <div>
    <TreeItem
      v-for="item in items"
      :key="item.id"
      :item="item"
      root
      :is-edit="isEdit"
    />
  </div>
</template>

<script>
import TreeItem from './TreeItem.vue';

export default {
  name: 'TreeList',
  components: { TreeItem },
  props: {
    items: { type: Array, default: () => [] },
    isEdit: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
};
</script>
