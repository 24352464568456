<template>
  <VCard>
    <VForm class="pa-6">
      <VRow justify="space-between">
        <VCol>
          <span class="tt-text-title-2">Создание связи</span>
        </VCol>

        <VCol
          cols="auto"
          class="mr-auto"
        >
          <TTBtn
            color="tt-light-mono-100"
            icon
            @click="handleCancel"
          >
            <VIcon>fal fa-times</VIcon>
          </TTBtn>
        </VCol>
      </VRow>

      <VRow>
        <VCol class="pb-0">
          <span class="d-block mb-2">Выбранные штатные должности</span>
          <TTChip
            v-for="sp in staffPositionSelected"
            :key="sp.id"
            class="mb-3 mr-3"
          >
            {{ sp.name }}
          </TTChip>
        </VCol>
      </VRow>

      <VRow>
        <VCol>
          <TTAutocomplete
            v-model="entity.parentId"
            :items="staffPositionUnselected"
            item-text="name"
            item-value="id"
            large
            label="Руководящая штатная должность"
            placeholder="Введите название"
            hide-details="auto"
          >
            <template #append>
              <VIcon>fal fa-search</VIcon>
            </template>
          </TTAutocomplete>
        </VCol>
      </VRow>

      <VRow
        class="mt-5"
        justify="end"
      >
        <VCol cols="auto">
          <TTBtn
            class="mr-5"
            color="tt-secondary"
            @click="handleCancel"
          >
            Отмена
          </TTBtn>
          <TTBtn
            color="tt-primary"
            :disabled="entity.parentId===-1"
            @click="handleSubmit"
          >
            Создать связь
          </TTBtn>
        </VCol>
      </VRow>
    </VForm>
  </VCard>
</template>

<script>

export default {
  name: 'StaffPositionLinkForm',
  props: {
    staffPositionsList: { type: Array, required: true },
  },
  data() {
    return {
      entity: { parentId: -1 },
    };
  },
  computed: {
    staffPositionSelected() {
      return this.staffPositionsList.filter((e) => e.isSelected);
    },
    staffPositionUnselected() {
      return [{ id: 0, name: 'Нет руководителя' }, ...this.staffPositionsList.filter((e) => !e.isSelected)];
    },
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
      this.entity.parentId = -1;
    },
    handleSubmit() {
      this.$emit('submit', this.entity);
      this.entity.parentId = -1;
    },
  },
};

</script>
